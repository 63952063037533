.main-analytics {
  // width: 80%;
  margin: 24px 36px;
}
.ant-card-body {
  padding: "12px";
}
.title-name {
  font-size: 2rem;
  font-weight: 700;
}
.title-home {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
