.main-home {
  // width: 80%;
  margin: 24px 36px;
}

.title-name {
  font-size: 2rem;
  font-weight: 700;
}

.title-home {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.money {
  font-size: 1.65rem;
  font-weight: 700;
  margin-left: auto;
}

.money-2 {
  font-size: 1.65rem;
  font-weight: 700;
}

.percentage {
  font-size: 0.75rem;
  opacity: 70%;
  margin-left: auto;
}

.addCards {
  background-color: rgba(230, 231, 238, .5);
  border: 1px solid rgba(230, 231, 238, 1);
  // border-color: rgba(255, 206, 86, 1);
  color: black;
}

.buttons {
  margin: auto 0;
  --tw-text-opacity: 1;
  color: rgb(103 107 137 / var(--tw-text-opacity)) !important;
  align-items: center;
  font-size: 1rem;
  text-decoration: none !important;
  font-weight: 500;
  &:hover {
    color: black !important;
    opacity: 1;
  }
}

.py-1 {
  padding-bottom: 8px;
  padding-top: 8px;
}

.px-3 {
  padding-left: 12px;
  padding-right: 12px;
}

.rounded {
  border-radius: 8px;
}

.bg {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.shadow {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow: 0 1px 4px 0 rgba(32, 41, 76, .04);
  --tw-shadow-colored: 0 1px 4px 0 var(--tw-shadow-color);
}